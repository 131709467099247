<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <div class="text-h6">Shipping Address</div>
        <address-component
          v-if="shopifyOrder.shipping_address"
          :address="shopifyOrder.shipping_address"
        />
      </v-col>
      <v-col cols="6">
        <div class="text-h6">Billing Address</div>
        <address-component :address="shopifyOrder.billing_address" />
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <v-row cols="12" v-if="order.shopifyJson.note || comments.length > 0">
      <v-col xs="12" sm="6">
        <div class="text-h6">Customer Notes</div>
        <span v-if="order.shopifyJson.note" style="background-color: yellow;">
          {{ order.shopifyJson.note }}
        </span>
      </v-col>
      <v-col xs="12" sm="6">
        <div class="text-h6">Staff Notes</div>
        <span v-if="comments">
          <div v-for="(comment, index) in comments" :key="index" style="background-color: yellow;">
            {{ comment.message }}
          </div>
        </span>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="linesNotInShipment.length > 0">
        <v-row align="center">
          <v-col>
            <div class="text-h6">Items not in a Shipment:</div>
          </v-col>
          <v-spacer />
          <v-col cols="2">
            <v-select
              :disabled="selectedLines.length < 1"
              label="Carrier"
              :items="carriers"
              v-model="carrier"
            />
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              small
              :disabled="selectedLines.length < 1"
              @click="createShipmentHandler()"
            >
              Create Shipment
            </v-btn>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-progress-linear indeterminate :active="querying" />
        </v-col>
        <v-data-table
          style="background-color:inherit;"
          show-select
          disable-pagination
          hide-default-footer
          disable-sort
          :items="linesNotInShipment"
          v-model="selectedLines"
          :headers="[
            { text: 'Qty', value: 'fulfillable_quantity' },
            { text: 'Product', value: 'title' },
            { text: 'Options', value: 'variant_title' },
            { text: 'Package', value: 'package'}
          ]"
        >
          <template v-slot:item.status="{ item }">
            <div v-if="getStrapiLine(item.id)">
              <line-item-status :lineItem="getStrapiLine(item.id)" />
            </div>
            <v-btn v-else width="100%" small @click="createNeedHandler(item)">
              I Need This {{ getStrapiLine(item.id) }}
            </v-btn>
          </template>
          <template v-slot:item.package="{ item }">
            <div>
              <v-select 
                :items="shippingPackages"
                :item-text="(item) => item.name"
                v-model="item.selectedPackage"
                return-object
                >
              </v-select>
            </div>
          </template>
        </v-data-table>
        <v-divider />
      </v-col>
      <v-col cols="12" v-if="order.shipments.length > 0">
        <div class="text-h6">Shipments:</div>

        <div v-for="(shipment, index) in order.shipments" :key="index">
          <shipment
            :shipment="shipment"
            :count="index + 1"
            @sync-order="sync()"
          />
        </div>
        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AddressComponent from './AddressComponent.vue'
import Shipment from './Shipment.vue'
import LineItemStatus from './LineItemStatus.vue'
import strapiApi from '../mixins/strapiApi'

export default {
  name: 'OrderDetail',
  props: { order: Object, shippingPackages: Array },
  mixins: [strapiApi],
  components: { AddressComponent, Shipment, LineItemStatus },
  data: () => ({
    querying: false,
    strapiLines: [],
    selectedLines: [],
    containers: [],
    packages: [],
    carrier: 'ups_ground',
    carriers: [
      { text: 'UPS Ground', value: 'ups_ground' },
      { text: 'USPS First Class (Hooks!)', value: 'usps_first_class_mail' },
      { text: "USPS Priority (Int'l cubes)!)", value: 'usps_priority_mail' },
    ],
    carrierIds: ['se-230317', 'se-168472', 'se-1253916'], //se-432036'] //<-shipengine stamps.com ups account,
    comments: [],
  }),
  watch: {
    async selectedLines() {
      Promise.all(
        this.selectedLines.map((line) => {
          return this.getStrapiProduct(line.product_id)
        })
      )
        .then((res) => {
          return (this.containers = res.flatMap((p) => p[0].package))
        })
        .then(async () => {
          const packageArr = await this.getPackages()
          this.packages = packageArr.flat()
        })
    },
  },
  computed: {
    unshippedLines() {
      return this.shopifyOrder.line_items.filter(
        (line) => line.fulfillable_quantity > 0
      )
    },
    linesNotInShipment() {
      return this.shopifyOrder.line_items
        .filter((line) => {
          return !this.order.shipments.some((shipment) => {
            return this.isLineInShipment(line.id, shipment)
          })
        })
        .filter((line) => line.fulfillable_quantity > 0)
    },
    linesInShipment() {
      return this.shopifyOrder.line_items.filter((line) => {
        return this.order.shipments.some((shipment) => {
          return this.isLineInShipment(line.id, shipment)
        })
      })
    },
    legacyShippedLines() {
      return this.shopifyOrder.line_items
        .filter((line) => {
          return !this.order.shipments.some((shipment) => {
            return this.isLineInShipment(line.id, shipment)
          })
        })
        .filter((line) => line.fulfillable_quantity < 1)
    },
    shopifyOrder() {
      return this.order.shopifyJson
    },
    carrierId() {
      if (this.carrier.includes('usps')) return this.carrierIds[1]
      if (this.carrier.includes('ups')) return this.carrierIds[2]
      return this.carrierIds[0]
    },
    shipTo() {
      return {
        Name: this.shopifyOrder.shipping_address.name,
        // phone: this.shopifyOrder.shipping_address.phone,
        AttentionName: this.shopifyOrder.shipping_address.company,
        Address: {
          AddressLine: [this.shopifyOrder.shipping_address.address1,this.shopifyOrder.shipping_address.address2],
          City: this.shopifyOrder.shipping_address.city,
          StateProvinceCode: this.shopifyOrder.shipping_address.province_code,
          PostalCode:this.shopifyOrder.shipping_address.zip,
          CountryCode: this.shopifyOrder.shipping_address.country_code,
          ResidentialAddressIndicator: 'True'
        }
      }
    },
    shipFrom() {
      return {
        Name: "Simple Wood Goods",
        phone: "513-654-6852",
        Address: {
          AddressLine: ["21 E. 73rd St"],
          City: "Cincinnati",
          StateProvinceCode: "OH",
          PostalCode:"45216",
          CountryCode: "US",
        }
      }
    },
    shipment() {
      return {
        carrierId: this.carrierId,
        service: this.carrier,
        ship_to: this.shipTo,
        ship_from: this.shipFrom,
        packages: this.packages,
      }
    },
  },
  methods: {
    toggleShipment(line) {
      const index = this.selectedLines.indexOf(line.id)
      if (index < 0) return this.selectedLines.push(line.id)
      return this.selectedLines.splice(index, 1)
    },
    createShipmentHandler() {
      this.querying = true
      this.createShipment({
        shopifyId: this.shopifyOrder.id.toString(),
        lineItems: this.selectedLines,
        carrierId: this.carrierId,
        shipTo: this.shipTo,
        shipFrom: this.shipFrom,
        packages: this.packages,
        carrier: this.carrier,
        shippedBy: this.$store.state.user,
      })
        .then(() => {
          this.sync()
        })
        .catch((err) => {
          this.querying = false
          console.error(err)
        })
    },
    async createNeedHandler(item) {
      const payload = {
        productId: item.product_id.toString(),
        shopifyId: item.id.toString(),
        name: item.name,
        variantId: item.variant_id.toString(),
        order: this.order.id.toString(),
        status: 'Need',
      }
      await this.createLineItem(payload)
      this.sync()
    },
    matchShipping() {
      if (!this.shopifyOrder.shipping_lines[0])
        return (this.carrier = 'ups_ground')
      const title = this.shopifyOrder.shipping_lines[0].title
      switch (title) {
        case 'UPS® Ground':
          this.carrier = 'ups_ground'
          break
        case 'UPS Ground':
          this.carrier = 'ups_ground'
          break
        default:
          this.carrier = 'ups_ground'
      }
    },
    isLineInShipment(lineId, shipment) {
      const lineItemIds = shipment.lineItems.map((lineItem) => lineItem.id)
      return lineItemIds.includes(lineId)
    },
    sync() {
      this.$emit('sync-order', this.order.id)
    },
    getStrapiProduct(id) {
      return this.getProducts(`shopifyId=${id}`)
    },
    getStrapiLine(id) {
      return this.strapiLines.find((sl) => {
        return sl.shopifyId == id
      })
    },
    getPackages() {
      return Promise.all(
        this.selectedLines.flatMap((line) => {
          const container = line.selectedPackage
          let perPackage = 1
          return this.getStrapiProduct(line.product_id)
            .then((res) => {
              perPackage = res[0].perPackage
              const count = Math.ceil(line.fulfillable_quantity / perPackage)
              return Array(count).fill({
                "Packaging": {
                  "Code": "02",
                  "Description": "Package"
                },
                "Dimensions": {
                  "UnitOfMeasurement": {
                    "Description": "Inches",
                    "Code": "IN"
                  },
                  "Length": container.depth.toString(),
                  "Width": container.width.toString(),
                  "Height": container.height.toString()
                },
                "PackageWeight": {
                  "UnitOfMeasurement": {
                    "Description": "Pounds",
                    "Code": "LBS"
                  },
                  "Weight": (line.grams > 0 ? line.grams/454 : 1).toString(),
                },
                ReferenceNumber: 
                  (line.variant_title ? [{ Value: line.title.slice(0,34) }, {Value: line.variant_title.slice(0,34)}] : [{ Value: line.title.slice(0,34) }])
                  // { Value: line.title || ""}, {Value: line.variant_title || " "}
                
              })
            })
            .catch((err) => {
              console.error(err)
            })
        })
      )
    },
    async getPackage(product_id){
      let p
      await this.getStrapiProduct(product_id).then((res) => {
        p = res[0].package
      })
      return p
    }
  },
  async mounted() {
    this.unshippedLines.map(async (line) => {
      let p 
      p = await this.getPackage(line.product_id)
      line.selectedPackage = p
    })
    this.matchShipping()
    this.comments = await this.getComments(this.order.id)

    const strapiLineArray = await Promise.all(
      this.order.shopifyJson.line_items.map(async (li) => {
        const res = await this.getLineItem(li.id)
        return res.data
      })
    )
    this.strapiLines = strapiLineArray.flat()
  },
}
</script>
